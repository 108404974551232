import { BaseHTMLAttributes, ElementType, FC, memo } from "react"
import { Typography } from "@/ui/Typography/Typography"
import { VIEW_PRODUCTS_GRID } from "@/utils/constants"
import { Product } from "../Catalog/Product"
import { SectionProductsGrid, StyledProductsGrid } from "../StyledProductsGrid"
import { ProductsGridType } from "../types"

export const ProductsGrid: FC<
  ProductsGridType & BaseHTMLAttributes<HTMLElement> & { as?: ElementType }
> = memo(
  ({
    titleList,
    view = VIEW_PRODUCTS_GRID,
    withBorder = false,
    products = [],
    children,
    as,
    favoritesGroupId,
    ...props
  }) => {
    return (
      <SectionProductsGrid>
        {titleList && <Typography variant={"h3"}>{titleList}</Typography>}
        <StyledProductsGrid
          as={as}
          data-view={view}
          data-with-border={withBorder}
          {...props}
        >
          {products.map((p, index) => (
            <Product
              product={p}
              view={view}
              key={p.uuid || String(index)}
              favoritesGroupId={favoritesGroupId}
            />
          ))}
          {children}
        </StyledProductsGrid>
      </SectionProductsGrid>
    )
  },
)

ProductsGrid.displayName = "ProductsGrid"
