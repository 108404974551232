import { cx } from "@linaria/core";
import type { BaseHTMLAttributes, FC } from "react";
import { memo } from "react";
import { Link } from "@/ui/Link";
import { cssIsSlider, StyledImageContainer } from "./StyledImageContainer";
export type ProductImageContainerPropsType = {
  path: string | null;
  name?: string;
  isSlider?: boolean;
};
export const ImageContainer: FC<ProductImageContainerPropsType & BaseHTMLAttributes<HTMLDivElement>> = memo(({
  children,
  path,
  name = "",
  isSlider,
  className,
  ...props
}) => {
  return <StyledImageContainer {...props} className={cx(!!isSlider && cssIsSlider, className)}>
      {!isSlider && <Link href={path || ""} title={name} scroll textIsHidden>
          {name}
        </Link>}
      {children}
    </StyledImageContainer>;
});
ImageContainer.displayName = "ImageContainer";