import { cx } from "@linaria/core";
import { FC, memo, MouseEvent, ReactNode } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { useRouter } from "next/router";
import type { ReportAdmissionPropsType } from "@/forms/ReportAdmission";
import { Button } from "@/ui/Button/Button";
import { ButtonArea } from "@/ui/Button/StyledButton";
import { cssIconShoppingCart, Icon } from "@/ui/Icon";
import { Modal } from "@/ui/Modal/Modal";
import { Typography } from "@/ui/Typography/Typography";
import { GTAG_EVENTS, ROUTES } from "@/utils/constants";
import { gtagSend } from "@/utils/common/helpers";
import { cssAddToCartDetailModifier, cssBtnAddToCart, cssButtonInCart, cssButtonInform, StyledAddToCartControl } from "./StyledAddToCartControl";
const ReportAdmission = dynamic(((() => import("../../../Forms/ReportAdmission").then(mod => mod.ReportAdmission)) as DynamicOptions<ReportAdmissionPropsType>), {
  ssr: false
});
export type AddToCartControlPropsType = {
  buttonAreaContent?: ReactNode;
  addToCart?: () => void;
  inCart?: boolean;
  isFetching?: boolean;
  isAvailable: boolean;
  variant?: "default" | "detail";
  uuid: string | null;
  disabled?: boolean;
};
export const AddToCartControl: FC<AddToCartControlPropsType> = memo(({
  buttonAreaContent,
  addToCart,
  inCart = false,
  isFetching = false,
  isAvailable,
  children,
  variant = "default",
  uuid,
  disabled = false
}) => {
  const {
    push
  } = useRouter();
  const isDetail = variant === "detail";
  const addToCartHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    gtagSend({
      event: GTAG_EVENTS.ADD_TO_CART
    });
    if (addToCart) {
      addToCart();
    }
  };
  const inCartHandle = () => {
    void push(ROUTES.cart);
  };
  if (!uuid) {
    return <></>;
  }
  if (isAvailable) {
    return <StyledAddToCartControl className={cx(isDetail && cssAddToCartDetailModifier)}>
          {!inCart ? <>
              <Button as={"div"} variant={isDetail ? "filled" : "translucent"} onClick={addToCartHandler} isFetching={isFetching} withArea={!!buttonAreaContent} size={isDetail ? "large" : undefined} disabled={disabled} aria-label="Добавить в корзину" className={cssBtnAddToCart}>
                {buttonAreaContent && <ButtonArea onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}>
                    {buttonAreaContent}
                  </ButtonArea>}
                <Typography variant={"span"}>В корзину</Typography>
                {isDetail && <Icon NameIcon={"ShoppingCart"} className={cssIconShoppingCart} size={"default"} />}
              </Button>
              {children}
            </> : <Button variant={"filled"} icon={"Check"} iconPosition={"right"} className={cssButtonInCart} isFetching={isFetching} size={isDetail ? "large" : undefined} onClick={inCartHandle} aria-label="В корзине">
              <Typography variant={"span"}>В корзине</Typography>
            </Button>}
        </StyledAddToCartControl>;
  }
  return <StyledAddToCartControl className={cx(isDetail && cssAddToCartDetailModifier)}>
        <Modal title={"Сообщить о поступлении"} variant={"rounded-0"} closeMode={"destroy"} disclosure={<Button variant={"translucent"} className={cssButtonInform} size={isDetail ? "large" : undefined} aria-label="Сообщить о поступлении">
              <Typography variant={"span"} style={{
        flex: 1
      }}>
                Сообщить о поступлении
              </Typography>
              {isDetail && <Icon NameIcon={"Email"} size={"default"} />}
            </Button>}>
          <ReportAdmission uuid={uuid} />
        </Modal>

        {children}
      </StyledAddToCartControl>;
});
AddToCartControl.displayName = "AddToCartControl";