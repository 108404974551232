import { cssIsActive } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { ButtonSizeAlias } from "@/ui/Button/constants";
import { ButtonVariantsPropsType } from "@/ui/Button/types";
import { IconNameType } from "@/ui/Icon";
import { cx } from "@linaria/core";
import { FC, memo } from "react";
const TEXT_NOT_ACTIVE = "Добавить в сравнение";
const TEXT_ACTIVE = "Удалить из сравнения";
export const ButtonToggleCompares: FC<{
  isCompares: boolean;
  isFetching?: boolean;
  toggleCompares: () => void;
  iconDelete?: IconNameType;
} & Pick<ButtonVariantsPropsType, "size" | "variant" | "isClean">> = memo(({
  isCompares,
  toggleCompares,
  isFetching = false,
  size = ButtonSizeAlias.SMALL,
  variant = "box",
  isClean,
  iconDelete = "CompareActive"
}) => {
  const text = isCompares ? TEXT_ACTIVE : TEXT_NOT_ACTIVE;
  return <Button variant={!!variant ? variant : "link"} icon={isCompares ? iconDelete : "CompareAdd"} className={cx(isCompares && cssIsActive)} onClick={toggleCompares} isFetching={isFetching} size={size} aria-label={text} role={"button"} type={"button"} title={text} isClean={isClean} />;
});
ButtonToggleCompares.displayName = "ButtonToggleCompares";