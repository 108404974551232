import { cx } from "@linaria/core";
import { BaseHTMLAttributes, forwardRef } from "react";
import { LayoutProductPropsType } from "@/components/Products/types";
import { VIEW_PRODUCTS_GRID } from "@/utils/constants";
import { SkeletonProduct } from "./parts/Skeleton/Skeleton";
import { cssProductAnimate, cssWithBadges, StyledProductTag } from "./StyledProduct";
export type ProductWrapperPropsType = LayoutProductPropsType & BaseHTMLAttributes<HTMLDivElement> & {
  isLoading?: boolean;
};
export const ProductWrapper = forwardRef<HTMLDivElement, ProductWrapperPropsType>(({
  uuid,
  isHover = false,
  isFavorite,
  viewProductsVariant = VIEW_PRODUCTS_GRID,
  availableStatus,
  isKit,
  isRemoved,
  children,
  isAnimate,
  className,
  sortWeight,
  isAvailable,
  categories,
  isLoading = false,
  withBadges,
  modifier,
  Component = StyledProductTag,
  isSample = false,
  isVariate = false,
  ...props
}, ref) => {
  return <Component {...props} data-view={viewProductsVariant} data-id={uuid} data-is-hover={isHover} data-is-favorite={isFavorite} data-is-kit={isKit} data-is-removed={isRemoved} data-is-available={isAvailable} data-is-sample={isSample} data-available-status={availableStatus} data-sort-weight={sortWeight} data-categories={categories} data-modifier={modifier} data-is-variate={isVariate} className={cx(isAnimate && cssProductAnimate, withBadges && cssWithBadges, className)} ref={ref}>
        {isLoading && <SkeletonProduct />}
        {children}
      </Component>;
});
ProductWrapper.displayName = "ProductWrapper";