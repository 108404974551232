import { EffectFade, Lazy, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { FC, memo } from "react";
import { ImageProps } from "next/image";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { cssSwiperProductImages, StyledPreview, StyledPreviewImage } from "./StyledPreview";
import { cssBullet, cssBulletActive } from "../../../../Swiper/StyledSwiper";
import { SwiperWrapper } from "../../../../Swiper/SwiperWrapper";
import { IMAGE_CONFIG } from "../../../constants";
export type PreviewSliderPropsType = {
  images: string[];
  alt: string;
  url?: string | null;
} & Omit<ImageProps, "src" | "draggable" | "alt">;
export const PreviewSlider: FC<PreviewSliderPropsType> = memo(({
  images,
  alt,
  url,
  priority,
  sizes,
  objectFit,
  width,
  height,
  layout
}) => {
  return <StyledPreview>
        <SwiperWrapper className={cssSwiperProductImages} modules={[EffectFade, Pagination, Lazy]} slidesPerView={1} lazy={{
      enabled: true,
      checkInView: true
    }} loop={false} centeredSlides={true} pagination={{
      bulletClass: cssBullet,
      bulletActiveClass: cssBulletActive
    }} paginationSequence count={images.length} speed={100} path={url || ""}>
          {images.map((img, i) => <SwiperSlide key={i} virtualIndex={i}>
              <StyledPreviewImage>
                <EntityImage imagePath={img} imageAlt={alt || ""} layout={layout || "intrinsic"} width={width || IMAGE_CONFIG.grid.size.width} height={height || IMAGE_CONFIG.grid.size.height} objectFit={objectFit || "cover"} quality={IMAGE_CONFIG.grid.quality} priority={!!priority && i === 0} sizes={sizes} />
              </StyledPreviewImage>
            </SwiperSlide>)}
        </SwiperWrapper>
      </StyledPreview>;
});
PreviewSlider.displayName = "PreviewSlider";